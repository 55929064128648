// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKFFQDusQ-r8eqJpH7kYxWc6qDCz-pZjs",
  authDomain: "cybersecurityseo17.firebaseapp.com",
  projectId: "cybersecurityseo17",
  storageBucket: "cybersecurityseo17.appspot.com",
  messagingSenderId: "681534668036",
  appId: "1:681534668036:web:b141a2b87688bbf2efe451"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;