import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://cybersecurity17-0fa74a562c47.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://cybersecurity17-0fa74a562c47.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (




    <>
      {
        banners.map(b => <section id="banner" className="banner-3 banner-3__space overflow-hidden theme-banner-3 parallax-element position-relative z-1 white-bg">
          <div className="container container-xxl line-container">
            <div className="line-1"><span /></div>
            <div className="line-2"><span /></div>
          </div>
          <div className="container container-xxl">
            <div className="banner-3__shape wow fadeIn animated" data-wow-delay=".2s">
              <img src="assets/imgs/banner-3/banner-shape.svg" alt="image not found" />
            </div>
            <div className="row">
              <div className="col-lg-7">
                <div className="banner-3__content">
                  <h1 className="banner-3__title mb-10 wow fadeIn animated" data-wow-delay=".1s"> <span>{b.bannerText}  </span> <br></br> {b.bannerHeading}  </h1>
                  <p className="mb-20 mb-xs-15 wow fadeIn animated" data-wow-delay=".3s">{b.bannerDetails}</p>
                  <div className="rr-btn__wrapper d-flex flex-wrap align-items-center mt-40 mt-sm-35 mt-xs-30 wow fadeIn animated" data-wow-delay=".5s">
                    <a href={b.buttonURL} className="rr-btn rr-btn__theme-4">
                      <span className="btn-wrap">
                        <span className="text-one">{b.buttonText}</span>
                        <span className="text-two">{b.buttonText}</span>
                      </span>
                    </a>

                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="banner-3__media wow fadeIn animated" data-wow-delay=".3s">
                  <img src={b.imageOne} alt="image not found" />
                </div>
              </div>
            </div>
          </div>
        </section>)
      }



    </>
  );
};

export default Banner;
